.zommed-in-image-background {
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background:rgba(0,0,0,0.9);
    z-index: 500;
}

.zommed-in-image {
    width: 50vw;
    height: auto;
    object-fit: cover;
    animation: 0.2s ease-out 0s 1 zoomInDesktop;
    z-index: 510;
}

@media only screen and (max-width: 1100px) {
    .zommed-in-image {
        width: 100%;
        animation: 0.2s ease-out 0s 1 zoomInMobile;
    }
}

@keyframes zoomInDesktop {
    0% {
        width: 30vw;
    }
    100% {
        width: 50vw;
    }
}

@keyframes zoomInMobile {
    0% {
        width: 70%;
    }
    100% {
        width: 100%;
    }
}