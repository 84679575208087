.post-wrapper {
    height: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
    max-width: 1200px;
    width: 100%;
    background-color: var(--white);
    margin-bottom: 3rem;
    border-radius: 20px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.featured-image {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
}

.post-title {
    padding-top: 1rem;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 5rem;
}

.post-time {
    color: var(--main-color);
    margin-top: 2rem;
    font-size: 0.8rem;
    margin-left: 2rem;
}

.column-split {
    display: flex;
    flex-direction: column;
}

.post-content {
    width: auto;
    margin: 0 5rem;
    padding-bottom: 5rem;
    background-color: var(--white);
}

.more-posts {
    width: 100%;
    padding: 4rem 10rem;
    border-radius: 0 0 20px 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: #e7e7e7;
    margin-top: 2rem;
}

.more-posts > h4 {
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
}

.osmrtnice-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1200px;
    height: fit-content;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.osmrtnica {
    height: 136px;
    width: 196px;
    margin: 2px;
    cursor: zoom-in;
}

@media only screen and (max-width: 1199px) {
    .column-split {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1000px) {
    .content-wrap {
        padding: 0;
        margin: 0;
    }

    .post-content {
        margin: 0 3rem;
    }

    .post-wrapper {
        border-radius: 0;
        margin: 0;
    }

    .more-posts {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

