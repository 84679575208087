.search-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search-component {
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    min-width: 2rem;
    background-color:var(--white);
}

.search-component:hover {
    color: var(--main-color);
    cursor: pointer;
}

.active {
    width: auto;
    background-color: #fff;
}

.active > .search-text {
    width: 500px;
    max-width: 60vw;
    padding-left: 1rem;
    animation-duration: 0.3s;
    animation-name: search-open;
    animation-timing-function: ease-in-out;
}

@keyframes search-open {
    0% {
        width: 0px;
        padding-left: 0rem;
    }
  
    100% {
        width: 500px;
        max-width: 60vw;
        padding-left: 1rem;
    }
  }

.search-icon {
    height: 1.4rem;
    width: 1.4rem;
}

.search-text {
    width: 0px;
    padding-left: 0;
    border: none;
    outline: none;
    height: 100%;
    border-radius: 1rem 0 0 1rem;
}