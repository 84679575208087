.smallhome-post-wrapper {
    width: 100%;
    margin: 5px;
    display: flex;
    cursor: pointer;
    background-color: var(--gray-light);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}

.hover-post > .smallhome-text > h5 {
    color: var(--main-color);
}

.smallhome-post-wrapper > img {
    min-height: 100px;
    width: 150px;
    object-fit: cover;
}

.smallhome-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    padding: 10px;
}