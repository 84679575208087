.sb-container {
    width: 250px;
    height: 250px;
    margin: 0.5rem;
    overflow: hidden;
    position: relative;
    cursor: default;
}

.sb-container > h3 {
    color: var(--white);
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 2px solid var(--white);
    margin: 20px;

    transition: height 0.3s ease-in-out;
}

.hover > h3 {
    height: 5rem;
}

.sb-container > div {
    background-color: var(--white);
    height: calc(250px - 3rem - 40px);
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 0.85rem;
}

.sb-container > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
}

.sb-container > .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #30303069;
    z-index: -1;

    transition: background-color 0.3s ease-in-out;
}

.hover > .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.sb-button {
    width: 70%;
    height: 2rem;
    background-color: var(--spice);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--white);
    border-radius: 5px;
    transform: translateY(-2.5rem);
}

.sb-button:hover {
    box-shadow: 0px 1px 1px 1px rgba(1,1,1,0.2);
}

.hover {
    box-shadow: 0px 0px 1px 1px rgba(1,1,1,0.2);
}