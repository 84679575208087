.animal-post-wrapper {
    width: 290px;
    height: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    background-color: var(--gray-light);
    cursor: pointer;
    /* border-radius: 10px; */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}

.animal-content-wrapper {
    padding: 1rem 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 50px;
}

.animal-post-wrapper > img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    overflow: hidden;
    /* border-radius: 10px 10px 0px 0px; */
}

.animal-content-wrapper > h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    line-height: 1.1rem;
    overflow: hidden;
    font-weight: 600;
    color: var(--black);
    text-transform: uppercase;
}

.animal-content-wrapper > p{
    font-size: 0.9rem;
    margin-top: 0.4rem;
}

.hover-post > .animal-content-wrapper > h3 {
    color: var(--main-color);
}