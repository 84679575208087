.dropdown-list {
    position: absolute;
    padding-top: 1rem;
}

.dropdown-frame {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.2);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.dropdown-frame > * {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-decoration: none;
    color: var(--black);
}

.dropdown-list-hidden {
    display: none;
}

.navigation-main {
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-item-container {
    height: 100%;
}

.dropdown-title {
    height: 100%;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1520px) {
    .nav-item-container {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: var(--gray-light);
        height: fit-content;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--white-darker);
    }

    .navigation-main {
        width: fit-content;
    }

    .dropdown-title {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dropdown-list {
        position: relative;
        padding-top: 1rem;
    }

    .dropdown-frame {
        background-color: var(--white-darker);
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2);
        width: 100vw;
        text-align: center;
        box-shadow: inset 0px 2px 2px 1px rgba(0, 0, 0, 0.1);
    }
}