.more-posts {
    display: flex;
    flex-direction: column;
    background-color: var(--white-darker);
}

.more-post {
    height: 4rem;
    background-color: var(--gray-light);
    border-left: 1px solid var(--main-color);
    padding: 0.5rem;
    cursor: pointer;
    width: 100%;
    margin-top: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hover-more-post {
    border-left: 2px solid var(--spice);
}

.post-time-small {
    color: var(--secondary-color);
    font-size: 0.8rem;
    margin-left: 1rem;
}

.more-post > h5 {
    font-weight: 600;
    font-size: 0.9rem;
    margin-left: 1rem;
}

@media only screen and (max-width: 1000px) {
    .more-post {
        height: fit-content;
    }
}