body {
    background-color: var(--white-darker);
}

.landing,
.landing-content {
    width: 100vw;
    height: 550px;
    position: absolute;
    top: 0;
    left: 0;
}

.landing {
    overflow: hidden;
    z-index: -10;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}



.landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 3rem;
}

.landing-content > img {
    width: 500px;
    height: auto;
}

.landing-content > span {
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding-top: 0.5rem;
    text-align: center;
}

#below-landing {
    padding-top: calc(550px);
}

.services-list {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: calc(100vh - 550px);
    height: fit-content;
    padding: 3rem 0rem;
}

.news-bcg  {
    width: 100%;
    max-width: 1200px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-bottom: 5rem;
    padding-top: 3rem;
    background-color: var(--white);
    border-radius: 20px 20px 0 0;
    padding: 2rem;
    padding-bottom: 4rem;
    padding-top: 3rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2); */
}

.top-bcg {
    width: 100%;
    max-width: 1200px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    background-color: var(--white);
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

.news > span {
    color: var(--gray);
}

.other-news > span {
    color: var(--gray);
    margin-top: 1rem;
}

.news {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}

.news-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    justify-content: space-around;
}

.other-news {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    align-items: center;
}

.other-news-column {
    width: 100%;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: row;
}

.aplikacija-bcg {
    background-image: url("../../public/media/mbcg.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: fit-content;
    width: 100vw;
}

.aplikacija-container {
    width: 700px;
    height: 700px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: var(--main-color);
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
    align-items: center;
    justify-content: center;
}

.aplikacija-container > img {
    height: 550px;
    width: auto;
    object-fit: cover;
}

.app-info > h2 {
    color: var(--white);
    text-transform: uppercase;
}

.app-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2rem;
}

.app-info > .logo {
    width: 150px;
    height: auto;
    object-fit: cover;
    margin-left: 25px;
} 

.app-info > span {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: var(--white);
    margin-bottom: 1rem;
}

.app-info > ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    color: var(--white);
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 2rem;
}

.app-info > ul > li {
    display: list-item;
    list-style-type: "✓";
}

.app-info > ul > li::marker {
    font-size: 2rem;
}

.download {
    width: 230px;
    height: auto;
    padding-top: 0.5rem;
    cursor: pointer;
}

.download > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rasporedi-bcg  {
    width: 100%;
    max-width: 1200px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
    /* padding-bottom: 5rem;
    padding-top: 3rem;
    background-color: var(--white);
    border-radius: 20px 20px 0 0;
    padding: 2rem;
    padding-bottom: 4rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2); */
}

.rasporedi-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 2rem 2rem 0 2rem ;
    padding: 0 0.5rem;
}

.kamioni-bcg {
    max-width: 1200px;
    width: 100%;
    height: 600px;
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: url('../../public/media/kamioni.png');
    background-size: cover;
}

.kamioni-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    height: 200px;
    margin-left: 1rem;
    margin-right: 1rem;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    border-top: 2px solid var(--main-color);
    /* background-color: var(--gray-light); */
    background-image: url('/public/media/kamioni-frame-bcg.jpg');
}

.kamioni-frame > span {
    font-size: 1.1rem;
    font-weight: 400;
}

.kamioni-button {
    width: 180px;
    height: 40px;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    margin-top: 2rem;
    cursor: pointer;
}

.kamioni-button:hover {
    transform: translateY(-1px);
}

.kamioni-button > span {
    color: white;
    font-weight: 500;
}

/* .kamioni-button:hover {
    transform: translateY(-1px);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border-top: 2px solid var(--spice);
}

.kamioni-button > span {
    font-size: 1.1rem;
    font-weight: 500;
    text-wrap: wrap;
    text-align: center;
} */

.smece-u-zute-bcg {
    width: 100%;
    max-width: 1200px;
    height: fit-content;
    border-radius: 0 0 20px 20px;
    margin-bottom: 4rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    padding: 1rem 0;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.smece-u-zute {
    max-width: 900px;
    width: 100%;
    height: auto;
    object-fit: cover;
    cursor: pointer;
}

.smece-u-zute:hover {
    transform: translateY(-1px);
}

@media only screen and (max-width: 1199px) {
    .news {
        flex-direction: column;
        width: 100%;
    }

    .news-list {
        width: 100%;
        justify-content: center;
    }

    .older-news-list {
        width: 100%;
        padding: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .services-list {
        margin: 2rem;
    }

    .rasporedi-container {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 1000px) {
    .news {
        padding: 0 10%;
    }
    
    .news-list {
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .other-news-column {
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .aplikacija-bcg {
        background-image: none;
        width: 100vw;
        background-color: var(--main-color);
    }
    
    
    .aplikacija-container > img {
        height: 400px;
        width: auto;
        margin-bottom: 2rem;
    }

    .aplikacija-container {
        width: 100%;
        border-radius: 0;
        justify-content: center;
        margin: 0;
        padding: 3rem 0;
        flex-direction: column;
        height: fit-content;
    }
    
    .app-info {
        padding-left: 0;
        justify-content: center;
        align-items: center;
    }
    
    .app-info > .logo {
        width: 150px;
        height: auto;
        object-fit: cover;
        margin-left: 0;
    } 
    
}