.home-post-wrapper {
    width: 350px;
    height: 400px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    background-color: var(--gray-light);
    cursor: pointer;
    /* border-radius: 10px; */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}

.news-content-wrapper {
    padding: 1rem 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.time {
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    font-size: 0.8rem;
}

.time > :not(span) {
    margin-left: 0.2rem;
    margin-right: 0.1rem;
}

.home-post-wrapper > img {
    width: 100%;
    height: 255px;
    object-fit: cover;
    overflow: hidden;
    /* border-radius: 10px 10px 0px 0px; */
}

.news-content-wrapper > h3 {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    line-height: 1.1rem;
    overflow: hidden;
    font-weight: 600;
    color: var(--black);
    padding: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    margin: -1rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
}

.news-content-wrapper > p{
    font-size: 0.9rem;
    margin-top: 0.4rem;
}

.hover-post > .news-content-wrapper > h3 {
    color: var(--main-color);
}

@media only screen and (max-width: 1000px) {
    .home-post-wrapper {
        width: 100%;
    }
}