.grid-gallery {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--white-darker);
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.grid-gallery-image {
    width: 250px;
    height: 200px;
    object-fit: cover;
    margin: 1px;
}