header {
    position: fixed;
    width: 100vw;
    background-color: var(--white);
    padding: 1rem;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    height: 4.5rem;
    z-index: 100;
}

.header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10rem;
    padding-right: 10rem;
    height: 100%;
}

img {
    height: 48px;
}

.navigation-links {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.header-list {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.navigation-links > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    color: var(--black);
    height: 100%;
}

.navigation-links *:hover {
    color: var(--main-color);
}

.logo-image {
    display: block;
    cursor: pointer;
}

.mobile-nav {
    display: none;
}

.menu-button {
    display: none;
}

.home {
    display: flex;
    align-items: center;
}

/* @media only screen and (max-width: 1400px) {
    .header-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin: 0;
    }
} */

@media only screen and (max-width: 1520px) {
    .header-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin: 0;
    }

    header {
        height: 3rem;
    }

    .header-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .navigation-links {
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        width: 100vw;
        top: 0;
        left: 0;
        margin-top: 3rem;
    }

    .navigation-links > * {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .home-naslovna {
        box-shadow: inset 0px 2px 2px 1px rgba(0, 0, 0, 0.1);
    }

    .home {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: var(--gray-light);
        border-bottom: 1px solid var(--white-darker);
    }

    .menu-button {
        display: block;
    }

    .hidden {
        display: none;
    }

    img {
        height: 32px;
    }
}

