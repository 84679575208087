.post-navigation-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}

.post-navigation-container > * {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.post-page-navigation {
    padding: 0.5rem;
    border-radius: 50%;
    background-color: var(--main-color);
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-weight: 600;
    cursor: pointer;
}