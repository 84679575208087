@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
    --white:#efefef;
    --white-darker: #e2e2e2;
    --black:#1f1f1f;
    --main-color:#009846;
    --secondary-color: #445352;
    --spice: #ec7615;
    --gray: #888888;
    --gray-light: #f5f5f5;
    --max-desktop-width: 1200px;
}

body {
    background-color: var(--white);
    font-family: "Open Sans", sans-serif !important;
    font-optical-sizing: auto !important;
    font-style: normal !important;
}

.table-overflow-wrapper {
    /* Fix for horizonal scroll on mobile devices */
    overflow-x: scroll;
}