.search-frame {
    padding: 4rem;
}

.rezultati-text {
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.top-text-wrapper {
    display: flex;
    flex-direction: row;
}

.sorry {
    font-style: italic;
}

@media only screen and (max-width: 1000px) {
    .top-text-wrapper {
        flex-direction: column;
    }

    .search-frame {
        padding: 7rem 4rem;
    }
}