.contact-content {
    padding: 0;
    flex-direction: row;
}

.contact-image {
    min-height: calc(100vh - 17rem);
    width: 500px;
    border-radius: 20px 0 0 20px;
    object-fit: cover;
}

.contact-form {
    margin: 5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.contact-input {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    top: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    margin-top: 0.5rem;
}

.contact-input:focus {
    outline: none !important;
    border:1px solid var(--main-color);
}

.message {
    height: 15rem;
    resize: none;
    width: 100%;
}

.contact-button {
    color: white;
    background-color: var(--main-color);
    padding: 5px 15px;
    border-radius: 5px;
    outline: 0;
    border: 0; 
    text-transform: uppercase;
    margin: 10px 0px;
    cursor: pointer;
    box-shadow: 0px 2px 2px lightgray;
}

.contact-button:hover {
    background-color: #09a753;
}

.contact-button:active {
    transform: translateY(1px);
    box-shadow: 0px 0px 0px lightgray;
}

@media only screen and (max-width: 1000px) {
    .contact-image {
        display: none;
    }
    
    .contact-button {
        width: 100%;
    }

    .contact-content {
        margin: 0;
        padding: 12rem 2rem;
        border-radius: 0;
        display: flex;
        flex-direction: column;
    }
}