.content-wrap {
    padding-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: var(--max-desktop-width);
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh; /* footer */

}

.page-content {
    height: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
    max-width: 1200px;
    width: 100%;
    background-color: var(--white);
    margin-bottom: 3rem;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    padding-bottom: 2rem;
    padding-left: 6rem;
    padding-right: 6rem;
    min-height: calc(100vh - 17rem);
    width: 100vw;
    /* padding-top: 4rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
}

.page-top-image {
    margin-left: 2rem;
    margin-right: 2rem;
    max-width: 1200px;
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

.page-content > div {
    width: 100%;
}

.page-content > p {
    width: 100%;
}

h1 {
    font-size: 2.3rem;
    font-weight: 500;
    color: var(--black);
    width: 100%;
    max-width: 1200px;
    text-align: center;
    margin-bottom: 4rem;
    /* text-transform: uppercase; */
}

/* h1::after {
    content: "";
    display: block;
    width: 100px;
    height: 4px;
    background-color: var(--main-color);
    margin-left: auto;
    margin-right: auto;
} */

h2 {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--black);
    width: 100%;
    max-width: 1200px;
    text-align: center;
    margin-bottom: 1rem;
}

/* h2::after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background-color: var(--main-color);
    margin-left: auto;
    margin-right: auto;
} */

h3 {
    color: var(--main-color);
    font-size: 1.1rem;
    font-weight: 400;
}

p {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

/* .more-posts-list {
    margin-top: 4rem;
} */

.animals-posts-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.animals-posts-list > * {
    margin: 0.5rem;
}

/* EU image correction */

.wp-image-106 {
    width: 500px;
    height: auto;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
}

/* Hide original gallery */

.wp-block-gallery {
    display: none !important;
}

.real-gallery .image-gallery-slide img {
    min-height: 550px;
    max-height: 100vh;
    object-fit: cover;
    width: auto;
}

.real-gallery.fullscreen .image-gallery-slide img {
    height: 95vh;
    width: auto;
}

.real-gallery .image-gallery {
    width: 80%;
    margin: auto;
}

/* Hide embed pdf, edit download link */

.wp-block-file__embed {
    display: none;
}

.wp-block-file {
    background-color: var(--gray-light);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    margin: 0.5rem;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.wp-block-file {
    background-color: var(--gray-light);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    margin: 0.5rem;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wp-block-file > .wp-block-file__button {
    background-color: var(--main-color);
}

.wp-block-file > a {
    max-width: 80%;
    overflow-wrap: break-word;
}

/* Fix background color of Osmrnica image */
[data-rmiz-modal-content] {
    background-color: rgb(31, 31, 31);
}

/* Raspored table */

.tablice-u-mjesecu {
    display: flex;
    flex-direction: column;
}

.dani-u-mjesecu {
    font-weight: 500;
}

.table {
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.table-head {
    background-color: #dadada;
    border-bottom: 1px solid var(--gray);
}

.table-head > * > * {
    width: 20%;
}

.table-head > * > * {
    font-weight: 600;
    padding: 4px;
}

.table-body > *:nth-child(even) {
    background-color: var(--white-darker);
}

.table-body > * > * {
    text-align: center;
    padding: 2px;
}

.nadi-ulicu {
    width: 50%;
    min-width: 350px;
    margin-bottom: 1rem;
}

.street-search-result {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: var(--white-darker);
}

/* Gallery fullscreen fix */

.fullscreen .image-gallery-center {
    height: 92vh;
}

.image-gallery-image {
    height: 100%;
}

/* Link za E-Groblje */
.link-egroblje {
    width: 170px !important;
    height: 2rem;
    background-color: var(--spice);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--white);
    border-radius: 5px;
    transform: translateY(-3.5rem);
    right: 0;
    margin-left: auto;
    cursor: pointer;
}

.link-egroblje:hover {
    transform: translateY(-3.55rem);
}

/* Podnosenje prigovora */

.podnosenje-prigovora {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.prigovor-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
}

.prigovor-info {
    width: 100%;
    margin: 2px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.prigovor-forma {
    width: 100%;
    margin: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prigovor-info-text {
    font-size: 1rem;
}

.prigovor-info-bold {
    font-weight: 700;
}

.prigovor-margin-top {
    margin-top: 2rem;
}

.prigovor-text-input {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    top: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    margin-top: 0.5rem;
}

.prigovor-poruka {
    height: 15rem;
    resize: none;
    width: 100%;
}

.prigovor-link {
    color: var(--main-color);
}

@media only screen and (max-width: 1000px) {
    .content-wrap {
        padding: 0;
        margin: 0;
    }

    .page-content {
        padding-left: 3rem;
        padding-right: 3rem;
        margin: 0;
        border-radius: 0;
    }

    .page-top-image {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    h1 {
        margin-bottom: 2rem;
        font-size: 1.8rem;
    }

    .wp-block-file > .wp-block-file__button {
        display: none;
    }

    .prigovor-wrapper {
        flex-direction: column;
    }
}