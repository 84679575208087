.raspored {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 250px;
    height: 300px;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    border-top: 2px solid var(--main-color);
    background-color: var(--gray-light);
    cursor: pointer;
}

.hover-raspored {
    transform: translateY(-1px);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border-top: 2px solid var(--spice);
}

.ricon {
    color: var(--white);
    width: 130px;
    background-color: var(--main-color);
    height: 130px;
    border-radius: 50%;
    margin-top: 2rem;
}

.raspored > span {
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 1rem;
    text-wrap: wrap;
    text-align: center;
}

.rsep {
    width: 80%;
    border-bottom: 1px solid var(--white-darker);
    margin-top: 1rem;
}

@media only screen and (max-width: 1199px) {
    .raspored {
        margin: 1rem;
    }
}