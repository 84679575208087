footer {
    bottom: 0;
    width: 100vw;
    height: 300px;
    background-color: var(--secondary-color);
    color: var(--white);
    padding: 2rem;
    box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
}

.footer-top {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-around;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--white);
}

.footer-top > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 2rem;
}

.info {
    padding-left: 1rem;
    color: var(--white);
    text-decoration: none;
    font-size: 1.3rem;
}

.footer-content {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
}

.footer-content > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    width: 33.33%;
}

.bold {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.link {
    color: var(--white);
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.icon-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1000px) {
    footer {
        height: fit-content;
    }

    .footer-top {
        flex-direction: column;
        align-items: center;
    }

    .footer-top > div {
        margin-bottom: 1rem;
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .footer-content > div {
        width: 100%;
        margin-bottom: 2rem;
    }
}