.map-area {
    width: 100vw;
    height: 100vh;
}

.marker-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.marker-container {
    position: absolute;
    display: flex;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #009846;
}

.marker-container > span {
    position: absolute;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
}

.marker-direction {
    width: 2.9rem;
    height: 2.9rem;
}